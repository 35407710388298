<template>
  <div id="PdfFolder" class="fill-height">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-container v-if="loading">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <span class="text-center text-overline"> Loading... </span>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="error">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>

    <!-- Content -->
    <v-container v-else-if="pdfGroups.length > 0 && pdfGroupIndex != null">
      <v-row justify="center" align="start">
        <!-- Content -->
        <v-col cols="12" sm="12" md="8">
          <v-container fluid>
            <!-- Breadcrumbs -->
            <v-row align="center" justify="start" class="indigo lighten-5 mb-2">
              <v-breadcrumbs
                class="text-transform-capitalize"
                :items="breadcrumbItems"
              >
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </v-row>

            <!-- PDfs -->
            <v-row
              v-if="pdfs.length > 0"
              align="start"
              justify="center"
              class="indigo lighten-5"
            >
              <v-col cols="12" sm="12">
                <v-list-item v-for="(pdfObj, i) in pdfs" :key="i">
                  <v-list-item-avatar class="mr-2 mr-md-4">
                    <v-icon class="indigo lighten-1" color="white">
                      mdi-file
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <span class="mr-2 text-transform-capitalize">
                        {{ pdfObj.pdfName }}
                      </span>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      Uploaded: {{ utcToDateText(pdfObj.id) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :href="pdfObj.pdfURL"
                      target="_blank"
                      class="text-transform-capitalize"
                      outlined
                      small
                    >
                      Download
                      <v-icon class="ml-1" size="18">mdi-download</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-col>

              <!-- Navigation Buttons Row -->
              <v-row class="mt-4" align="center" no-gutters>
                <v-col cols="12" sm="12" class="d-flex justify-center">
                  <v-btn
                    class="mx-1"
                    color="primary darken-2"
                    :loading="loading"
                    @click="initFetchPdfs('prev')"
                    text
                  >
                    <v-icon>mdi-chevron-left</v-icon> Previous
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    color="primary darken-2"
                    :loading="loading"
                    :disabled="pdfGroups.length < 10"
                    @click="initFetchPdfs('next')"
                    text
                  >
                    Next
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>

            <!-- Loading -->
            <v-row v-else-if="loading" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>

            <!-- Loading -->
            <v-row v-else align="center" justify="center">
              <v-alert type="info"
                >PDFs will be added soon to this folder :)</v-alert
              >
            </v-row>

            <!-- Premium Pass Ad -->
            <v-row align="center" justify="start" class="mt-2">
              <v-col cols="12" sm="12" class="px-0">
                <v-card class="card-gradient-2" rounded="0">
                  <v-card-text class="white--text">
                    <v-chip class="white--text" color="blue darken-3"
                      >PlusMinus</v-chip
                    >

                    <div class="text-h5 mt-2 ml-2">
                      Premium Pass
                      <v-icon color="amber darken-2" class="ml-2"
                        >mdi-crown</v-icon
                      >
                    </div>

                    <p class="ml-2 mt-1 text-h6 amber--text">
                      Unlimited Access to all exams
                    </p>

                    <div class="d-flex justify-space-between align-center">
                      <v-list-item two-line>
                        <v-list-item-content class="white--text">
                          <v-list-item-subtitle
                            class="grey--text text--lighten-2"
                            >Starts for only</v-list-item-subtitle
                          >
                          <v-list-item-title>Rs. 99/month</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <div>
                        <v-btn to="/premium-pass" color="white" elevation="0">
                          Unlock Now
                        </v-btn>
                        <div class="text-center mt-1">
                          <small> Test Series, Mock Tests and More </small>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- Google Ad -->
        <v-col
          cols="12"
          sm="12"
          md="4"
          :class="$vuetify.breakpoint.mdAndUp ? 'mt-16' : ''"
        >
          <!-- free-pdf-folder-ad -->
          <Adsense
            data-ad-client="ca-pub-7807573894216192"
            data-ad-slot="2658949869"
            is-new-ads-code="no"
            data-ad-format="auto"
            data-full-width-responsive="yes"
          >
          </Adsense>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "PdfFolder",
  metaInfo: {
    title: "Student PdfFolder",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Student PdfFolder Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: {},
  computed: {
    userData() {
      return this.$store.getters.userData;
    },

    breadcrumbItems() {
      var items = [
        {
          text: "Free PDFs",
          disabled: false,
          href: "/free-pdfs",
        },
        {
          text: "Group",
          disabled: false,
          href: "/free-pdfs/0",
        },
        {
          text: "Folder",
          disabled: false,
          href: "",
        },
      ];

      if (this.pdfGroups.length > 0 && this.pdfGroupIndex != null) {
        // Group
        items[1].text = this.pdfGroups[this.pdfGroupIndex].name;
        items[1].href = `/free-pdfs/${this.pdfGroupIndex}`;

        // Folder
        items[2].text =
          this.pdfGroups[this.pdfGroupIndex].folders[this.pdfFolderIndex];
      }

      return items;
    },
  },
  data: () => ({
    error: "",
    loading: true,
    snackbar: false,
    snackbarText: "",

    pdfGroupIndex: null,
    pdfFolderIndex: null,
    selectedPdfPayload: null,
    firstAndLastVisible: null,
    pdfGroups: [],
    pdfs: [],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
    },

    utcToDateText(utc) {
      return dayjs.utc(utc * 1000).format("DD-MM-YY");
    },

    fetchPdfGroups() {
      // get courses array from store
      const pdfGroupsArr = this.$store.getters.pdfGroups;

      //if not found in store
      if (pdfGroupsArr.length <= 0 || typeof pdfGroupsArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getPDFDoc")
          .then((res) => {
            if (res) {
              this.pdfGroups = res.pdfGroups;

              const selectedPdfPayload = {
                folder:
                  this.pdfGroups[this.pdfGroupIndex].folders[
                    this.pdfFolderIndex
                  ],
                group: this.pdfGroups[this.pdfGroupIndex].name,
                requestType: null,
              };

              this.selectedPdfPayload = selectedPdfPayload;

              this.fetchPdfs(selectedPdfPayload);
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.pdfGroups = pdfGroupsArr;

        const selectedPdfPayload = {
          folder:
            this.pdfGroups[this.pdfGroupIndex].folders[this.pdfFolderIndex],
          group: this.pdfGroups[this.pdfGroupIndex].name,
          requestType: null,
        };

        this.selectedPdfPayload = selectedPdfPayload;

        this.fetchPdfs(selectedPdfPayload);

        this.setLoading(false);
      }
    },

    initFetchPdfs(requestType) {
      const selectedPdfPayload = {
        folder: this.pdfGroups[this.pdfGroupIndex].folders[this.pdfFolderIndex],
        group: this.pdfGroups[this.pdfGroupIndex].name,
        requestType,
      };

      this.selectedPdfPayload = selectedPdfPayload;

      this.fetchPdfs(selectedPdfPayload);
    },

    fetchPdfs(selectedPdfPayload) {
      this.setLoading(true);

      // { folder, group, requestType: null }
      var payload = selectedPdfPayload;

      // do not get prev or next if diffrent group/folder
      if (
        this.selectedPdfPayload == null ||
        payload.folder != this.selectedPdfPayload.folder ||
        payload.group != this.selectedPdfPayload.group
      ) {
        payload.requestType = null;
        this.firstAndLastVisible = null;
      }

      this.selectedPdfPayload = selectedPdfPayload;

      switch (payload.requestType) {
        case "prev":
          payload.doc = this.firstAndLastVisible.firstVisible;
          break;
        case "next":
          payload.doc = this.firstAndLastVisible.lastVisible;
          break;
        default:
          payload.requestType = null;
      }

      this.$store
        .dispatch("getpdfs", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "PDFs fetched successfully :)";
            this.pdfs = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (payload.requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "No PDFs in this folder yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },
  },
  mounted() {
    const pdfGroupIndex = this.$route.params.groupID;
    const pdfFolderIndex = this.$route.params.folderID;

    // check valid pdfGroupIndex
    if (
      isNaN(pdfGroupIndex) ||
      pdfGroupIndex.toString().length < 1 ||
      isNaN(pdfFolderIndex) ||
      pdfFolderIndex.toString().length < 1
    ) {
      this.$router.push("/404");
      return;
    }
    this.pdfGroupIndex = pdfGroupIndex;
    this.pdfFolderIndex = pdfFolderIndex;

    this.fetchPdfGroups();
  },
};
</script>

<style scoped>
.card-gradient-2 {
  background: #4776e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8e54e9,
    #4776e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #4776e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>